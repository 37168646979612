export const button = {
  "button.login": 'Đăng nhập',
  "button.register": 'Đăng ký',
  "button.logout": 'Đăng xuất',
  "button.search": 'Tìm',
  "button.add": 'Thêm',
  "button.download": 'Tải xuống',
  "button.import": 'Nhập dữ liệu',
  "button.massChange": 'Thay đổi',
  "button.cancel": 'Đóng',
  "button.confirm": 'Xác nhận',
  "button.edit": 'Sửa',
  "button.back": 'Quay lại',
  "button.view": 'Xem',
  "button.save": 'Lưu',
  "button.list": 'Danh sách',
  "button.delete": 'Xóa',
}

export const error = {
  "error.required": "Đây là thông tin bắt buộc",
  "error.maxLength": "Thông tin quá dài",
  "error.minLength": "Thông tin quá ngắn",
  "error.max": "Số lượng quá lớn",
  "error.min": "Số lượng quá nhỏ",
  "error.pattern": "Thông tin không hợp lệ",
  "error.valueAsNumber": "Đây phải là số hợp lệ",
  "error.valueAsDate": "Đây phải là ngày hợp lệ",
  "error.filetype": "Tập tin không hợp lệ",
  "error.filesize": "Tập tin nhỏ hơn 10MB",
}

export const message = {
  "message.success": "Thành công",
  "message.warning.busy": "Vui lòng thử lại sau",
  "message.error": "Có lỗi",
}

export const input = {
  "input.department.select": "Chọn phòng ban",
  "input.phone.label": "Số điện thoại",
  "input.phone.placeholder": "Nhập số điện thoại",
  "input.phone.select": "Chọn số điện thoại",
  "input.password.label": "Mật khẩu",
  "input.password.placeholder": "Nhập mật khẩu",
  "input.password.hint": 'Mật khẩu tối thiểu 8 ký tự',
  "input.name.label": "Họ và Tên",
  "input.name.placeholder": "Nhập Họ và Tên",
  "input.name.select": "Chọn tên",
  "input.search.label": "Từ khoá",
  "input.search.placeholder": "Nhập Từ khoá",
  "input.email.label": "Thư điện tử",
  "input.email.placeholder": "Nhập thư điện tử",
  "input.email.select": "Chọn thư điện tử",
  "input.email.title": "Tiêu đề",
  "input.email.content": "Nội dung",
  "input.cmnd.label": "CMND",
  "input.cmnd.placeholder": "Nhập cmnd",
  "input.cmnd.select": "Chọn CMND",
  "input.role.label": "Vai trò",
  "input.role.placeholder": "Chọn vai trò",
  "input.dob.label": "Ngày sinh",
  "input.status.label": "Trạng thái",
  "input.status.select": "Chọn trạng thái",
  "input.avatar.label": "Ảnh đại diện",
  "input.address.placeholder": "Nhập địa chỉ",
  "input.address.label": "Địa chỉ",
  "input.address.select": "Chọn địa chỉ",
  "input.file.text-button": "Tải lên",
  "input.file.image-hint": "PNG, JPG hoặc GIF lên đến %s",
  "input.file.result-template": "File tải lên: %s",

  "input.news-title.label": "Tiêu đề",
  "input.news-title.placeholder": "Nhập tiêu đề",
  "input.news-content.label": "Nội dung",
  "input.news-content.placeholder": "Nhập nội dung",
  "input.news-predefine.label": "Phần xem trước",
  "input.news-predefine.placeholder": "Nhập phần xem trước",
  "input.news-author.label": "Tác giả",
  "input.news-author.placeholder": "Nhập tác giả",
  "input.news-category.label": "Danh mục tin tức",
  "input.news-category.placeholder": "Nhập danh mục tin tức",
  "input.news-tag.label": "Thẻ tin tức",
  "input.news-tag.placeholder": "Nhập thẻ tin tức",

  "input.tag-type.label": "Loại thẻ",
  "input.tag-type.placeholder": "Nhập loại thẻ",
  "input.tag-name.label": "Tên hiển thị của thẻ",
  "input.tag-name.placeholder": "Nhập tên hiển thị của thẻ",
  "input.tag-group.label": "Nhóm thẻ",
  "input.tag-group.placeholder": "Nhập nhóm thẻ",

  "input.category-type.label": "Loại danh mục",
  "input.category-type.placeholder": "Nhập loại danh mục",
  "input.category-name.label": "Tên danh mục",
  "input.category-name.placeholder": "Nhập tên danh mục",
  "input.category-group.label": "Nhóm danh mục",
  "input.category-group.placeholder": "Nhập nhóm danh mục",

  "input.email-title.label": "Tiêu đề",
  "input.email-title.placeholder": "Nhập tiêu đề",
  "input.email-content.label": "Nội dung",
  "input.email-content.placeholder": "Nhập nội dung",
  "input.email-type.label": "Loại thư điện tử",
  "input.email-type.placeholder": "Nhập loại thư điện tử",
  
  "input.media-name.label": "Tên tập tin",
  "input.media-name.placeholder": "Nhập tên tập tin",
  "input.media-alt.label": "ALT tập tin",
  "input.media-alt.placeholder": "Nhập ALT tập tin",
  "input.media-type.label": "Kiểu tập tin",
  "input.media-type.placeholder": "Nhập kiểu tập tin",

  "input.access-code-type.label": "Loại mã",
  "input.access-code-type.placeholder": "Nhập loại mã",
  "input.access-code-status.label": "Trạng thái",
  "input.access-code-status.placeholder": "Nhập trạng thái",
  "input.access-code-times.label": "Số lần",
  "input.access-code-times.placeholder": "Nhập số lần",
  "input.access-code-duration.label": "Khoảng thời gian",
  "input.access-code-duration.placeholder": "Nhập khoảng thời gian",

  "input.role-type.label": "Vai trò",
  "input.role-type.placeholder": "Nhập vai trò",
  "input.role-name.label": "Tên vai trò",
  "input.role-name.placeholder": "Nhập tên vai trò",
  "input.permissions.label": "Quyền",
  "input.permissions.placeholder": "Nhập quyền",

  "input.generate-command.label": "Lệnh",
  "input.generate-command.placeholder": "Nhập lệnh",
  "input.generate-status.label": "Trạng thái",
  "input.generate-status.placeholder": "Nhập trạng thái",

  "input.product-name.label": "Tên",
  "input.product-name.placeholder": "Nhập tên",
  "input.product-description.label": "Mô tả",
  "input.product-description.placeholder": "Nhập mô tả",
  "input.product-price.label": "Giá",
  "input.product-price.placeholder": "Nhập giá",
  "input.product-discount.label": "Giảm giá",
  "input.product-discount.placeholder": "Nhập giảm giá",
  "input.product-type.label": "Loại",
  "input.product-type.placeholder": "Nhập loại",
  "input.product-quantity.label": "Số lượng",
  "input.product-quantity.placeholder": "Nhập số lượng",
  "input.product-duration.label": "Thời gian",
  "input.product-duration.placeholder": "Nhập thời gian",
  "input.product-status.label": "Trạng thái",
  "input.product-status.placeholder": "Nhập trạng thái",
  "input.product-category.label": "Danh mục",
  "input.product-category.placeholder": "Nhập danh mục",
  "input.product-tag.label": "Thẻ",
  "input.product-tag.placeholder": "Nhập thẻ",
  "input.order-serial.label": "Mã",
  "input.order-serial.placeholder": "Nhập mã",
  "input.order-phone.label": "Điện thoại",
  "input.order-phone.placeholder": "Nhập điện thoại",
  "input.order-address.label": "Địa chỉ",
  "input.order-address.placeholder": "Nhập địa chỉ",
  "input.order-status.label": "Trạng thái",
  "input.order-status.placeholder": "Nhập trạng thái",
  "input.payment-status.label": "Trạng thái thanh toán",
  "input.payment-status.placeholder": "Nhập trạng thái thanh toán",
  "input.payment-type.label": "Loại thanh toán",
  "input.payment-type.placeholder": "Nhập loại thanh toán",
  "input.voucher-code.label": "Mã",
  "input.voucher-code.placeholder": "Nhập mã",
  "input.voucher-value.label": "Giá trị",
  "input.voucher-value.placeholder": "Nhập giá trị",
  "input.voucher-min.label": "Nhỏ nhất",
  "input.voucher-min.placeholder": "Nhập nhỏ nhất",
  "input.voucher-max.label": "Lớn nhất",
  "input.voucher-max.placeholder": "Nhập lớn nhất",
  "input.voucher-type.label": "Loại mã",
  "input.voucher-type.placeholder": "Nhập loại mã",
  "input.condition-type.label": "Điều kiện",
  "input.condition-type.placeholder": "Nhập điều kiện",
  "input.condition-value.label": "Giá trị điều kiện",
  "input.condition-value.placeholder": "Nhập giá trị điều kiện",
}

export const tooltip = {
  "tooltip.editable": "Click để chỉnh sửa",
}

export const button = {
  "button.login": 'Login',
  "button.register": 'Register',
  "button.logout": 'Logout',
  "button.search": 'Search',
  "button.add": 'Add',
  "button.download": 'Download',
  "button.import": 'Import',
  "button.massChange": 'Change',
  "button.cancel": 'Cancel',
  "button.confirm": 'OK',
  "button.edit": 'Edit',
  "button.back": 'Back',
  "button.view": 'View',
  "button.save": 'Save',
  "button.list": 'List',
  "button.delete": 'Delete',
}

export const error = {
  "error.required": "Required",
  "error.maxLength": "The text is so long",
  "error.minLength": "The text is so short",
  "error.max": "The number is so large",
  "error.min": "The number is so small",
  "error.pattern": "Invalid information",
  "error.valueAsNumber": "Invalid number",
  "error.valueAsDate": "Invalid date",
  "error.filetype": "Invalid file type",
  "error.filesize": "File size less than 10MB",
}

export const message = {
  "message.success": "Success",
  "message.warning.busy": "Please try later",
  "message.error": "Something went wrong",
}

export const input = {
  "input.department.select": "Select department",
  "input.phone.label": "Phone Number",
  "input.phone.placeholder": "Enter phone number",
  "input.phone.select": "Select phone number",
  "input.password.label": "Password",
  "input.password.placeholder": "Enter password",
  "input.password.hint": 'At least 8 characters long',
  "input.name.label": "Name",
  "input.name.placeholder": "Enter name",
  "input.name.select": "Select name",
  "input.search.label": "Keyword",
  "input.search.placeholder": "Enter keyword",
  "input.email.label": "Email",
  "input.email.placeholder": "Enter email",
  "input.email.select": "Select email",
  
  "input.cmnd.label": "CMND",
  "input.cmnd.placeholder": "Enter cmnd",
  "input.cmnd.select": "Select CMND",
  "input.role.label": "Role",
  "input.role.placeholder": "Select role",
  "input.dob.label": "Date of birth",
  "input.status.label": "Status",
  "input.status.select": "Select status",
  "input.avatar.label": "Avatar",
  "input.address.placeholder": "Enter address",
  "input.address.label": "Address",
  "input.address.select": "Select address",
  "input.file.text-button": "Click to upload",
  "input.file.image-hint": "PNG, JPG or GIF up to %s",
  "input.file.result-template": "Uploaded content: %s",

  "input.news-title.label": "Title",
  "input.news-title.placeholder": "Enter title",
  "input.news-content.label": "Content",
  "input.news-content.placeholder": "Enter content",
  "input.news-predefine.label": "Predefine",
  "input.news-predefine.placeholder": "Enter predefine",
  "input.news-author.label": "Author",
  "input.news-author.placeholder": "Enter author",
  "input.news-category.label": "Category",
  "input.news-category.placeholder": "Enter category",
  "input.news-tag.label": "News tag",
  "input.news-tag.placeholder": "Enter news tag",

  "input.tag-type.label": "Tag type",
  "input.tag-type.placeholder": "Enter tag type",
  "input.tag-name.label": "Tag name",
  "input.tag-name.placeholder": "Enter tag name",
  "input.tag-group.label": "Tag group",
  "input.tag-group.placeholder": "Enter tag group",

  "input.category-type.label": "Category type",
  "input.category-type.placeholder": "Enter category type",
  "input.category-name.label": "Category name",
  "input.category-name.placeholder": "Enter category name",
  "input.category-group.label": "Category group",
  "input.category-group.placeholder": "Enter category group",

  "input.email-title.label": "Title",
  "input.email-title.placeholder": "Enter Title",
  "input.email-content.label": "Content",
  "input.email-content.placeholder": "Enter Content",
  "input.email-type.label": "Email Type",
  "input.email-type.placeholder": "Enter Email Type",

  "input.media-name.label": "Media Name",
  "input.media-name.placeholder": "Enter Media Name",
  "input.media-alt.label": "Media Alt",
  "input.media-alt.placeholder": "Enter Media Alt",
  "input.media-type.label": "Media Type",
  "input.media-type.placeholder": "Enter Media Type",

  "input.access-code-type.label": "Access Code Type",
  "input.access-code-type.placeholder": "Enter Access Code Type",
  "input.access-code-status.label": "Access Code Status",
  "input.access-code-status.placeholder": "Enter Access Code Status",
  "input.access-code-times.label": "Times",
  "input.access-code-times.placeholder": "Enter times",
  "input.access-code-duration.label": "Duration",
  "input.access-code-duration.placeholder": "Enter duration",

  "input.role-type.label": "Role Type",
  "input.role-type.placeholder": "Enter Role Type",
  "input.role-name.label": "Role Name",
  "input.role-name.placeholder": "Enter Role Name",
  "input.permissions.label": "Permissions",
  "input.permissions.placeholder": "Enter Permissions",

  "input.generate-command.label": "Command",
  "input.generate-command.placeholder": "Enter Command",
  "input.generate-status.label": "Status",
  "input.generate-status.placeholder": "Enter Status",

  "input.product-name.label": "Name",
  "input.product-name.placeholder": "Enter name",
  "input.product-description.label": "Description",
  "input.product-description.placeholder": "Enter description",
  "input.product-price.label": "Price",
  "input.product-price.placeholder": "Enter price",
  "input.product-discount.label": "Discount",
  "input.product-discount.placeholder": "Enter discount",
  "input.product-type.label": "Type",
  "input.product-type.placeholder": "Enter type",
  "input.product-quantity.label": "Quantity",
  "input.product-quantity.placeholder": "Enter quantity",
  "input.product-duration.label": "Duration",
  "input.product-duration.placeholder": "Enter duration",
  "input.product-status.label": "Status",
  "input.product-status.placeholder": "Enter status",
  "input.product-category.label": "Category",
  "input.product-category.placeholder": "Enter category",
  "input.product-tag.label": "Tag",
  "input.product-tag.placeholder": "Enter tag",
  "input.order-serial.label": "Serial",
  "input.order-serial.placeholder": "Enter serial",
  "input.order-phone.label": "Phone",
  "input.order-phone.placeholder": "Enter phone",
  "input.order-address.label": "Address",
  "input.order-address.placeholder": "Enter address",
  "input.order-status.label": "Order Status",
  "input.order-status.placeholder": "Enter order Status",
  "input.payment-status.label": "Payment Status",
  "input.payment-status.placeholder": "Enter payment Status",
  "input.payment-type.label": "Payment Type",
  "input.payment-type.placeholder": "Enter payment Type",
  "input.voucher-code.label": "Code",
  "input.voucher-code.placeholder": "Enter code",
  "input.voucher-value.label": "Value",
  "input.voucher-value.placeholder": "Enter value",
  "input.voucher-min.label": "Min",
  "input.voucher-min.placeholder": "Enter min",
  "input.voucher-max.label": "Max",
  "input.voucher-max.placeholder": "Enter max",
  "input.voucher-type.label": "Type",
  "input.voucher-type.placeholder": "Enter type",
  "input.condition-type.label": "Condition",
  "input.condition-type.placeholder": "Enter condition",
  "input.condition-value.label": "Condition Value",
  "input.condition-value.placeholder": "Enter condition value",
}

export const tooltip = {
  "tooltip.editable": "Click to edit",
}